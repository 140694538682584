// src/styles/GlobalStyles.ts
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body,
  html {
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: #777;
    font-weight: 400;
    width: 100% !important;
    height: 100% !important;
  }
  h2,
  h3,
  h4 {
    font-family: 'Raleway', sans-serif;
  }
  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 15px;
  }
  a {
    color: #608dfd;
    font-weight: 400;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: #608dfd;
    text-decoration: none;
  }
`;

export default GlobalStyles;
