// src/pages/DeleteAccount.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import JsonData from '../../data/data.json';
import { SiteData } from '../../types/siteDataTypes';

const APP_NAME = 'Business Chots';
const DEVELOPER_NAME = 'Chots AI';

const DeleteAccountWrapper = styled.div`
  background-color: #fff;
  color: #333;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const MainHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #5ca9fb;
  margin-bottom: 20px;
`;

const SectionHeader = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #5ca9fb;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 16px;
`;

const List = styled.ol`
  list-style-type: decimal;
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const DeleteAccount: React.FC = () => {
    const [siteData, setSiteData] = useState<SiteData | null>(null);

  useEffect(() => {
    setSiteData(JsonData as SiteData);
  }, []);

  return (
    <>
    {siteData ? (
      <>
    <Header data={siteData.BusinessChotsPrivacyHeader} />
    <DeleteAccountWrapper>
      <MainHeader>Delete Your {APP_NAME} Account</MainHeader>
      <Paragraph>
        At {DEVELOPER_NAME}, we respect your decision if you choose to delete your account. Below are the steps to delete your {APP_NAME} account and information about how we handle your data upon deletion.
      </Paragraph>

      <SectionHeader>Steps to Delete Your Account</SectionHeader>
      <List>
        <ListItem>
          <BoldText>Open the App:</BoldText> Launch the {APP_NAME} app on your device.
        </ListItem>
        <ListItem>
          <BoldText>Access the Side Menu:</BoldText> Tap on the menu icon to open the side menu.
        </ListItem>
        <ListItem>
          <BoldText>Navigate to User Preferences:</BoldText> Select <i>"User Preferences"</i> from the menu options.
        </ListItem>
        <ListItem>
          <BoldText>Initiate Account Deletion:</BoldText> Scroll down and tap on <i>"Delete Account"</i>.
        </ListItem>
        <ListItem>
          <BoldText>Confirm Deletion:</BoldText> Follow the on-screen instructions to confirm the deletion of your account.
        </ListItem>
      </List>

      <SectionHeader>Data Deletion and Retention Policy</SectionHeader>
      <Paragraph>
        When you delete your {APP_NAME} account:
      </Paragraph>
      <List>
        <ListItem>
          <BoldText>Personal Information:</BoldText> Your personal information, including name, email address, and business details, will be permanently deleted from our active databases.
        </ListItem>
        <ListItem>
          <BoldText>Chat Messages:</BoldText> All conversations and chat messages with AI assistants associated with your account will be deleted within 30 days of account deletion.
        </ListItem>
        <ListItem>
          <BoldText>Backup Archives:</BoldText> Some data may remain in backup archives for up to 90 days but will not be used for any purpose other than compliance with legal obligations.
        </ListItem>
        <ListItem>
          <BoldText>Aggregated Data:</BoldText> We may retain aggregated, non-personally identifiable data for analytical purposes.
        </ListItem>
      </List>

      <SectionHeader>Need Assistance?</SectionHeader>
      <Paragraph>
        If you encounter any issues during the account deletion process or have any questions, please contact our support team at{' '}
        <a href={`mailto:${siteData.AppsData.customerSupportEmail}?subject=Regarding ${APP_NAME}'s Account Deletion`}>
        {siteData.AppsData.customerSupportEmail}</a>.
      </Paragraph>
    </DeleteAccountWrapper>
    <Footer />
    </>
      ) : (
        <p>Loading...</p>
      )}
      </>
  );
};

export default DeleteAccount;
