// src/components/Header.tsx
import React from 'react';
import styled from 'styled-components';

// Styled Components

const HeaderSection = styled.header`
  position: relative;
`;

const Intro = styled.div`
  display: table;
  width: 100%;
  height: 90vh;
  background-image: url(${require('../assets/images/header-bg1.jpg')});
  background-color: #e5e5e5;
  background-size: cover;
  background-position: center;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  padding: 0;
`;

const Container = styled.div`
  max-width: 1140px; /* Equivalent to Bootstrap's container */
  margin: 0 auto;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -15px;
`;

const IntroText = styled.div`
  flex: 0 0 66.6667%; /* Equivalent to col-md-8 */
  max-width: 66.6667%;
  padding: 0 15px;
  text-align: center;
  margin-top: 350px;
  margin-bottom: 200px;

  @media (max-width: 768px) {
    margin-top: 150px;
    margin-bottom: 100px;
  }
`;

const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 42px;
  }
`;

const TitleSpan = styled.span`
  font-weight: 800;
  color: #5ca9fb;
`;

const Subtitle = styled.p`
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto 60px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 40px;
  }
`;

const CustomButton = styled.a`
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: none;
  display: inline-block;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #6372ff;
    background-image: none;
  }
`;

interface HeaderProps {
  data?: {
    title: string;
    paragraph: string;
  };
}

const Header: React.FC<HeaderProps> = (props) => {
  return (
    <HeaderSection id="header">
      <Intro>
        <Overlay>
          <Container>
            <Row>
              <IntroText>
                <Title>
                  {props.data ? props.data.title : 'Loading'}
                  <TitleSpan></TitleSpan>
                </Title>
                <Subtitle>{props.data ? props.data.paragraph : 'Loading'}</Subtitle>
                {/* <CustomButton href="#features">Learn More</CustomButton> */}
              </IntroText>
            </Row>
          </Container>
        </Overlay>
      </Intro>
    </HeaderSection>
  );
};

export default Header;
