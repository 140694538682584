// src/routes/AppRoutes.tsx
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/businessChots/PrivacyPolicy';
import DeleteAccount from '../pages/businessChots/DeleteAccount';
import ScrollToTop from '../components/ScrollToTop';

const AppRoutes: React.FC = () => (
  <>
    {/* Include ScrollToTop component */}
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/business-chots/privacy-policy/" element={<PrivacyPolicy />} />
      <Route path="/business-chots/delete-account/" element={<DeleteAccount />} />
    </Routes>
  </>
);

export default AppRoutes;
