// src/pages/PrivacyPolicy.tsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import JsonData from '../../data/data.json';
import { SiteData } from '../../types/siteDataTypes';

const Content = styled.main`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
`;

const PrivacyPolicyWrapper = styled.div`
  background-color: #fff;
  color: #333;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
`;

const MainHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #5ca9fb;
  margin-bottom: 20px;
`;

const SectionHeader = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: #5ca9fb;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const EmailLink = styled.a`
  font-size: 16px;
  color: #5ca9fb;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 8px;
  position: relative;
  padding-left: 24px;

  &::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #5ca9fb;
    font-size: 20px;
    line-height: 1;
  }
`;

const PrivacyPolicy: React.FC = () => {
  const [siteData, setSiteData] = useState<SiteData | null>(null);

  useEffect(() => {
    setSiteData(JsonData as SiteData);
  }, []);
  
const APP_NAME = 'Business Chots';

  return (
    <>
    {siteData ? (
      <>
    <Header data={siteData.BusinessChotsPrivacyHeader} />
    <PrivacyPolicyWrapper>
      <MainHeader>Privacy Policy</MainHeader>
      <Paragraph>Effective Date: {siteData.AppsData.effectiveDate}</Paragraph>

      <SectionHeader>1. Introduction</SectionHeader>
      <Paragraph>
        {APP_NAME} ("we," "our," or "us") operates the {APP_NAME} app ("the App").
        We value your privacy and are committed to protecting your personal
        information. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you use our App. By using the App, you
        consent to the practices described in this Privacy Policy.
      </Paragraph>

      <SectionHeader>2. Information We Collect</SectionHeader>
      <Paragraph>
        <BoldText>Personal Information:</BoldText> When you register to use the App,
        we may collect personal information such as your name, email address, and
        business details.
      </Paragraph>
      <Paragraph>
        <BoldText>Business Details:</BoldText> Information about your business that
        you provide through the App, including company name, industry, size, and
        other relevant data.
      </Paragraph>
      <Paragraph>
        <BoldText>Chat Messages:</BoldText> All conversations between you and the AI
        assistants are recorded and stored in our chats database. This data is
        retained to enhance the quality and relevance of responses provided by the
        AI assistants.
      </Paragraph>

      <SectionHeader>3. How We Use Your Information</SectionHeader>
      <Paragraph>We use the information we collect to:</Paragraph>
      <List>
        <ListItem>Provide, operate, and maintain the App.</ListItem>
        <ListItem>
          Enhance and personalize your experience with the App by tailoring
          responses based on your business details.
        </ListItem>
        <ListItem>Conduct internal analytics to improve our services.</ListItem>
        <ListItem>Comply with legal obligations.</ListItem>
      </List>

      <SectionHeader>4. Data Retention</SectionHeader>
      <Paragraph>
        <BoldText>Chat Messages:</BoldText> Your chat messages are stored for a
        period of 2-3 years from the date of creation. After this period, the
        messages may be automatically deleted from our systems. This retention period
        is implemented to ensure that the AI assistants can provide accurate and
        contextually relevant responses over time.
      </Paragraph>
      <Paragraph>
        <BoldText>Business Details:</BoldText> You can update, change, or delete your
        business details at any time through the App's settings. Deleted information
        will be removed from our active databases but may remain in backup archives
        for a limited time.
      </Paragraph>

      <SectionHeader>5. Data Sharing and Disclosure</SectionHeader>
      <Paragraph>
        We do not sell, trade, or rent your personal information to third parties.
        However, we may share your data with:
      </Paragraph>
      <Paragraph>
        <BoldText>Internal Analytics:</BoldText> Your data may be shared within{' '}
        {APP_NAME} for internal analytics to enhance our services.
      </Paragraph>
      <Paragraph>
        <BoldText>Third-Party Services:</BoldText> We integrate with third-party
        services, such as Glassfy for payment and subscription management and Datadog
        for logging. These services may have access to your information as necessary
        for them to perform their functions, but they are obligated not to disclose
        or use it for any other purpose.
      </Paragraph>
      <Paragraph>
        <BoldText>Legal Requirements:</BoldText> We may disclose your information if
        required to do so by law or in response to valid requests by public
        authorities.
      </Paragraph>

      <SectionHeader>6. Your Rights and Choices</SectionHeader>
      <Paragraph>
        <BoldText>Access and Correction:</BoldText> You have the right to access and
        correct your personal information at any time. You can do this by logging
        into your account and accessing the account settings.
      </Paragraph>
      <Paragraph>
        <BoldText>Data Deletion:</BoldText> You may request the deletion of your
        personal data by contacting us directly. Please note that certain data may be
        retained as required by law or for legitimate business purposes.
      </Paragraph>
      <Paragraph>
        <BoldText>Opt-Out:</BoldText> You may opt-out of certain data collection
        practices by adjusting your preferences in the App's settings. However,
        opting out may affect the functionality of the App.
      </Paragraph>

      <SectionHeader>7. Security of Your Information</SectionHeader>
      <Paragraph>
        We implement reasonable administrative, technical, and physical security
        measures to protect your personal information from unauthorized access, use,
        or disclosure. However, please be aware that no method of transmitting
        information over the Internet or storing data is completely secure, and we
        cannot guarantee absolute security.
      </Paragraph>

      <SectionHeader>8. Children's Privacy</SectionHeader>
      <Paragraph>
        The App is not intended for use by individuals under the age of 13. We do not
        knowingly collect personal information from children under 13. If we become
        aware that we have inadvertently collected such information, we will take
        steps to delete it as soon as possible.
      </Paragraph>

      <SectionHeader>9. Changes to This Privacy Policy</SectionHeader>
      <Paragraph>
        We may update this Privacy Policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. When we
        make changes, we will update the "Effective Date" at the top of this
        document. Your continued use of the App after any such changes constitutes
        your acceptance of the revised Privacy Policy.
      </Paragraph>

      <SectionHeader>10. Contact Us</SectionHeader>
      <Paragraph>
        If you have any questions about this Privacy Policy or our data practices,
        please contact us at{' '}
        <EmailLink
          href={`mailto:${siteData.AppsData.customerSupportEmail}?subject=Regarding ${APP_NAME}'s Privacy Policy`}
        >
          {siteData.AppsData.customerSupportEmail}
        </EmailLink>
        .
      </Paragraph>
    </PrivacyPolicyWrapper>
    <Footer />
    </>
      ) : (
        <p>Loading...</p>
      )}
      </>
  );
};

export default PrivacyPolicy;

