// src/pages/Home.tsx
import React, { useEffect, useState } from 'react';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import Features from '../components/Features';
import About from '../components/About';
import Services from '../components/Services';
import Gallery from '../components/Gallery';
import Testimonials from '../components/Testimonials';
import Team from '../components/Team';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import JsonData from '../data/data.json';
import { SiteData } from '../types/siteDataTypes';

const Home: React.FC = () => {
  const [siteData, setSiteData] = useState<SiteData | null>(null);

  useEffect(() => {
    setSiteData(JsonData as SiteData);
  }, []);
//https://react-landing-page-template-93ne.vercel.app/#page-top
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {siteData && siteData != null ? (
        <>
          {/* <Navigation /> */}
          <Header data={siteData.Header} />
          {/* <Features data={siteData.Features} />
          <About data={siteData.About} />
          <Services data={siteData.Services} />
          <Gallery data={siteData.Gallery} />
          <Testimonials data={siteData.Testimonials} />
          <Team data={siteData.Team} />
          <Contact data={siteData.Contact} /> */}
          <Footer />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Home;
