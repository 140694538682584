// src/components/Navigation.tsx
import React, { useState } from 'react';
import styled from 'styled-components';

const Nav = styled.nav`
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  padding: 15px 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
`;

const Container = styled.div`
  max-width: 1140px; /* Equivalent to Bootstrap's container */
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: row;
`;

const NavbarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavbarBrand = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const LogoImage = styled.img`
  height: 40px;
  width: auto;
`;

const NavbarToggle = styled.button`
  background: none;
  border: none;
  font-size: 28px;
  cursor: pointer;
  display: none;
  color: #333;

  @media (max-width: 767px) {
    display: block;
  }
`;

const NavbarCollapse = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};

  @media (min-width: 768px) {
    display: flex !important;
  }
`;

const NavUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`;

const NavLi = styled.li`
  margin: 0;

  @media (min-width: 768px) {
    margin-left: 20px;
  }
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  padding: 10px;
  display: block;

  &:hover {
    color: #5ca9fb;
  }
`;

const Navigation: React.FC = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <Nav>
      <Container>
        <NavbarHeader>
          <NavbarBrand href="/">
            {/* Replace text with logo image */}
            <LogoImage src={require('../assets/images/Colorlogo-nobackground.png')} alt="Logo" />
          </NavbarBrand>
          <NavbarToggle onClick={handleToggle}>
            &#9776;
          </NavbarToggle>
        </NavbarHeader>
        {/* <NavbarCollapse open={navbarOpen}>
          <NavUl>
            <NavLi>
              <NavLink href="#features">Features</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#about">About</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#services">Services</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#portfolio">Gallery</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#testimonials">Testimonials</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#team">Team</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href="#contact">Contact</NavLink>
            </NavLi>
          </NavUl>
        </NavbarCollapse> */}
      </Container>
    </Nav>
  );
};

export default Navigation;
