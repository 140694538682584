// src/App.tsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import AppRoutes from './routes/AppRoutes';
import Navigation from './components/Navigation';

const App: React.FC = () => (
  <Router>
    <GlobalStyles />
    <Navigation />
    <AppRoutes />
  </Router>
);

export default App;
