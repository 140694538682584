// src/components/Footer.tsx
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  padding: 1rem 2rem;
  background-color: #f1f1f1;
  text-align: center;
`;

const FooterLinks = styled.div`
  a {
    margin: 0 0.5rem;
    text-decoration: none;
    color: #333;
  }
`;

const Footer: React.FC = () => (
  <FooterContainer>
    <FooterLinks>
      <Link to="/">Home</Link>
      {/* <Link to="/business-chots/privacy-policy/">Privacy Policy</Link>
      <Link to="/business-chots/delete-account/">Delete Account</Link> */}
    </FooterLinks>
    <p>&copy; {new Date().getFullYear()} Chots.AI</p>
  </FooterContainer>
);

export default Footer;
